<template>
  <div class="wrapper">
    <div class="wrapper__container">
      <jublia-header />
      <div class="container">
        <section id="terms" class="mb-8">
          <!-- <jublia-h1 class="mb-6">{{ $t('mycos.title') }}</jublia-h1>
          <jublia-p v-html="$t('mycos.text')" />
           <jublia-p v-html="$t('mycos.text2')" />
            <jublia-h1 v-html="$t('mycos.resourse')" />-->
          <jublia-h3 class="DINBold text-jublia-bluest mb-8" style="line-height: 4rem;">
            {{ $t('home.about.title') }}</jublia-h3
          >
        
          <br/>
          <p class="title DINMedium mx-0" style="margin-left: 0 !important">
            {{ $t('home.about.subtitle') }}
          </p>
          <br />
          <jublia-p class="text DINRegular" v-html="$t('home.about.text')" />
       
          <div
            class="relative flex w-3/4 sm:w-2/4 my-8 mx-auto justify-between sm:mx-12"
          >
            <img
              style="width:140px; height:153px"
              src="@/assets/img/about/Asset381.png"
              alt="Bottle Jublia"
              width="80"
              height="234"
              class="bottle mx-4"
            />
            <img
               style="width:140px; height:153px"
              src="@/assets/img/about/Asset391.png"
              alt="Bottle Jublia"
              width="80"
              height="234"
              class="bottle"
            />
          </div>
          <jublia-p class="text DINRegular" v-html="$t('home.about.text2')" />
          <br/>
          <p class="title DINMedium mx-0" style="margin-left: 0 !important">
            {{ $t('home.about.subtitle2') }}
          </p>
          <br/>
          <jublia-p class="text DINRegular" v-html="$t('home.about.list')" />
          <div
            class="relative flex w-full my-8 mx-auto justify-around sm:w-full"
          >
            <img
              style="width: 140px; height: 126x"
              src="@/assets/img/about/Asset501.png"
              alt="Bottle Jublia"
              width="80"
              height="234"
              class="bottle mx-4"
            />
            <img
              style="width:140px; height: 126x"
              src="@/assets/img/about/Asset511.png"
              alt="Bottle Jublia"
              width="80"
              height="234"
              class="bottle"
            />
          </div>
          <br/>
          <p class="title DINMedium mx-0" style="margin-left: 0 !important">
            {{ $t('home.about.subtitle3') }}
          </p>
          <br/>
          <jublia-p class="text" v-html="$t('home.about.list2')" />
<br/>
          <jublia-p
            class="py-8 DINBold text-jublia-bluest px-4"
            style="
              border: 1px solid #006699;
              border-radius: 30px;
              font-size: 12pt;
             
            "
          >
          
          <p> <img
              style="width:25px; height: 25px;display: initial !important; margin-bottom: 0.2rem;"
               src="@/assets/img/about/Asset491.png"
              alt="Bottle Jublia"
              width="80"
              height="234"
              class="bottle mr-2"/>
             {{ $t('home.about.boxt') }}</p>
             {{ $t('home.about.box') }}

          </jublia-p>
         
 
          <div class="w-full mr-auto md:w-4/5 lg:w-2/3 sm:w-4/5  "  >
          <br/>
          <router-link
         
         
              to="error-form-treatment"
              
              class="no-underline DINBold text-jublia-bluest"
            >
            <jublia-button
              bgpurple
               style=" background:#006699 !important;"
              class="w-full flex justify-between items-center bg-jublia-bluest"
              :blu="true"
            >
             <img
                src="@/assets/img/about/Icone.png"
                alt="next button"
                width="26"
                height="26"
                style="margin-bottom: .3rem;"
              />
              <div class="text-xl font-bold flex-grow">
                {{ $t('home.about.btn') }}
              </div>
             
            </jublia-button>
          </router-link>
          </div>
          <br/>
          <jublia-p class="text DINRegular" v-html="$t('home.about.text3')" />
            <br/>
          <jublia-p
            class="py-4 DINBold text-jublia-bluest sm:px-8 "
            style="
              border: 1px solid #006699;
              border-radius: 30px;
              font-size: 12pt;
              text-align: center;
            "
            v-html="$t('home.about.box2')"
          /><br/>
          <p class="title DINMedium mx-0" style="margin-left: 0 !important">
            {{ $t('home.about.subtitle4') }}
          </p>
          <br/>
          <jublia-p class="text" v-html="$t('home.about.list3')" />
          <br/>
          <p class="title DINMedium mx-0" style="margin-left: 0 !important">
            {{ $t('home.about.subtitle5') }}
          </p>
          <br/>
          <jublia-p class="text p DINRegular mb-2" v-html="$t('home.about.text4')" />
          <jublia-p class="text mb-2" v-html="$t('home.about.list4')" />
          <jublia-p class="text p DINRegular" v-html="$t('home.about.text5')" />
          <br/>
           <div class="w-full  mt-8 md:mx-auto md:w-4/5 lg:w-2/3">
           <router-link
             
              :to="{ path: 'mycos', hash: '#terms' }" 
              class="no-underline DINBold text-jublia-bluest"
            >
            <jublia-button
              bgpurple
              class="w-full flex justify-between items-center"
            >
              <div class="text-xl font-bold  mr-2">
                {{ $t('common.next') }}
              </div>
              <img
                src="@/assets/icons/next.png"
                alt="next button"
                width="10"
                height="10"
              />
            </jublia-button>
           </router-link>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import JubliaHeader from '@/components/header/jublia-header'
import JubliaH1 from '@/components/text/jublia-h1'
import JubliaP from '@/components/text/jublia-p'
import JubliaButton from '@/components/button/jublia-button'

export default {
  components: {
    'jublia-header':JubliaHeader,
    'jublia-button':JubliaButton,
    'jublia-h1': JubliaH1,
    'jublia-p':JubliaP,
  },
}
</script>
<style scoped>
ul{
  margin-left: 1rem !important;
 
}
.li{
  font-size:16px !important;
}
p{
  font-weight: 500 !important;
  font-size:18px !important;
}
.li{
  text-align:justify !important;
}
.mx-12 {
    
    margin-right: 0px !important;
}

</style>