<template>

  <div class="wrapper-menu">
    <div class="wrapper__container">
        <jublia-load class="m-auto" v-if="load"/>
  </div> 
 </div>    
</template>

<script>
//import JubliaLoad from '@/components/loader/jublia-loader'
import JubliaH1 from '@/components/text/jublia-h1'
import JubliaP from '../components/text/jublia-p.vue'
import JubliaLoader from '../components/loader/jublia-loader'

export default {
  components: {
    'jublia-load':JubliaLoader,
    'jublia-h1':JubliaH1,
    'jublia-p':JubliaP
  
   // Drop
  },
   data: () => ({
    load: true,
    }),
  mounted:function()
  {
  this.start('')
  },
  methods: {
 async start(type) {
    let v=this.$route.params.name.split("&")[0]
        const query = this.$route.query;
            
  let resolved =  this.$router.resolve({name: v})       

   if(v){
  if(query.lang&&['en','fr'].includes(query.lang)){
  this.$i18n.setLocale(query.lang)
  localStorage.setItem('lang',query.lang)
  
  }
  setTimeout(()=>{
 
if(resolved.matched.length>0&&resolved.meta.userAuth==undefined) {
 
this.$store.dispatch('login/loginDin',{ din:'iPwajcBYba9xKCqEjH41eYpKyII' }).then(()=>{
           if(this.state!='error'){
            if(v)
           this.$router.push(`/${v}`)
         }
          if(this.state=='error'){
          this.load= true
          return null
         }
           }).catch((e)=>{
          this.load = false
        })
        }
  },3000)

        }
 },
  }
}
</script>

<style scoped>
.wrapper-menu {
     @apply w-full fixed top-0  left-0;
    z-index: 99;
  min-height: 100vh;
  @media all and (min-width: 768px) {
    @apply flex items-center justify-center flex-col bg-jublia-gray ;
  }
}
.container-menu {
  @apply h-full w-11/12 flex flex-col justify-start z-20  border-jublia-purple border-4;
   @apply rounded-2xl;
   margin: auto !important;
   min-width: 21rem;
   min-height:27rem;
  @media all and (min-width: 768px) {
    max-width: 900px;
   /* margin: 0 auto;*/
  }
}
</style>
